<template>
  <v-container fluid style="padding:2px;width: 100%;max-width: 700px;">
    <v-row class="ma-0 pa-0 mt-3">
      <v-col cols="12">
        <v-card flat class="pa-0" style="border: 1px solid #CCCCCC;">
          <v-card-title style="color:white;" class="px-4 py-1 blue darken-2">
            Hồ sơ cá nhân
          </v-card-title>
          <v-card-text class="pa-3">
            <div style="display: block; padding: 6px; font-size: 16px;">
              <div
                style="display: inline-block;  font-weight: 350;"
                :style="{
                  width: $vuetify.breakpoint.mdAndUp ? '240px' : '100%',
                }"
              >
                Email:
              </div>
              <div style="display: inline-block; font-weight: 600;">
                {{ user.email }}
              </div>
            </div>

            <div style="display: block; padding: 6px; font-size: 16px;">
              <div
                style="display: inline-block;  font-weight: 350;"
                :style="{
                  width: $vuetify.breakpoint.mdAndUp ? '240px' : '100%',
                }"
              >
                Họ và tên:
              </div>
              <div style="display: inline-block; font-weight: 600;">
                {{ user.fullname }}
              </div>
            </div>

            <div style="display: block; padding: 6px; font-size: 16px;">
              <div
                style="display: inline-block;  font-weight: 350;"
                :style="{
                  width: $vuetify.breakpoint.mdAndUp ? '240px' : '100%',
                }"
              >
                Điện thoại:
              </div>
              <div style="display: inline-block; font-weight: 600;">
                {{ user.mobile }}
              </div>
            </div>

            <div style="display: block; padding: 6px; font-size: 16px;">
              <div
                style="display: inline-block;  font-weight: 350;"
                :style="{
                  width: $vuetify.breakpoint.mdAndUp ? '240px' : '100%',
                }"
              >
                Quyền cấp phép:
              </div>
              <div style="display: inline-block; font-weight: 600;">
                {{ user.role }}
              </div>
            </div>
            <div>
              <div
                style="display: inline-block;  font-weight: 350;"
                :style="{
                  width: $vuetify.breakpoint.mdAndUp ? '240px' : '',
                }"
              >
                <v-img
                  width="192"
                  height="192"
                  contain
                  style="border:1px solid #DDDDDD;display:inline-block;"
                  :src="user.avatar ? user.avatar : '/imgs/user-placehold.jpg'"
                />
              </div>

              <div
                class=" ml-2"
                style="display: inline-block; vertical-align:top;"
              >
                <input
                  type="file"
                  style="display: none"
                  ref="imageInput"
                  accept="*/*"
                  @change="handleFileUpload"
                />

                <v-btn
                  @click="startPickImage"
                  color="teal"
                  outlined
                  style="min-width:20px;width:46px;"
                >
                  <v-icon>
                    add_photo_alternate
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <div style="display: block; padding: 6px; font-size: 16px;">
              <div
                style="display: inline-block;  font-weight: 350;"
                :style="{
                  width: $vuetify.breakpoint.mdAndUp ? '240px' : '100%',
                }"
              >
                Bạn có muốn đổi mật khẩu ?
              </div>
              <v-btn
                dark
                color="primary"
                style="text-transform:none;margin: 0px;height:30px;"
                @click="updatePass"
              >
                Đổi mật khẩu
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      flat
      style="top: 40px;"
      v-model="snackbar"
      dark
      :timeout="snack_timeout"
      :color="colorSnackBar"
      top
    >
      {{ snackbar_message }}
    </v-snackbar>

    <dialog-update-password
      :show="dialogUpdatePass"
      @cancel="dialogUpdatePass = false"
      @saveDone="updateDone"
    ></dialog-update-password>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import dialogUpdatePassword from "./UpdatePasswordDialog.vue";

export default {
  data() {
    return {
      newPassword: "",
      snackbar: false,
      snack_timeout: 3000,
      snackbar_message: "",
      colorSnackBar: "info",
      dialogUpdatePass: false,
      user: {},
    };
  },
  components: {
    dialogUpdatePassword,
  },
  computed: {
    ...mapGetters({
      userId: "userId",
      token: "token",
      role: "role",
      fullname: "getFullName",
    }),
    getRole() {
      let item = {};
      if (this.user.role === "ASnind010") {
        item.roleStr = "Super Admin";
        item.roleColor = "indigo";
      }
      if (this.user.role === "ninAnd033") {
        item.roleStr = "Admin";
        item.roleColor = "red";
      }
      if (this.user.role === "Perio020") {
        item.roleStr = "Operator";
        item.roleColor = "blue";
      }
      if (this.user.role === "neergeen040") {
        item.roleStr = "Service Engineer";
        item.roleColor = "orange";
      }
      if (this.user.role === "tugets050") {
        item.roleStr = "Guest";
        item.roleColor = "teal";
      }
      return item;
    },
  },
  methods: {
    startPickImage() {
      this.$refs.imageInput.click();
    },
    handleFileUpload: function() {
      var file = this.$refs.imageInput.files[0];
      if (file !== undefined) {
        var self = this;
        self.result = "";
        let formData = new FormData();
        formData.append("file", file);
        self.axios
          .post("https://file.ponotek.com/uploads", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function(res) {
            self.user.avatar = res.data.content;
            self.updateUser();
          })
          .catch(function(err) {
            console.log(err);
          });
      }
    },
    updateUser() {
      var self = this;
      var path = this.$root.apiUser + "/users/update/" + self.user._id;
      this.axios
        .put(path, self.user, { headers: { "x-auth": self.token } })
        .then((response) => {
          if (response.data.status === "OK") {
            this.showMessageApp("Cập nhật thành công!");
          } else {
            alert("Error: " + response.data.message);
          }
        });
    },
    updatePass() {
      this.dialogUpdatePass = true;
    },
    updateDone(response) {
      this.dialogUpdatePass = false;
      this.showMessageApp(response);
    },
    showMessageApp(message, type) {
      this.snackbar = true;
      this.snackbar_message = message;
      this.colorSnackBar = !type ? "info" : type;
    },
    fetchUserInfo() {
      if (this.userId && this.token) {
        this.axios
          .get(this.$root.apiUser + "/users/get/" + this.userId, {
            headers: { "x-auth": this.token },
          })
          .then((res) => {
            if (res.data.status == "OK") {
              this.user = res.data.content;
              console.log(this.user);
            } else {
              console.log(res.data.message);
            }
          })
          .catch(console.log);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchUserInfo();
    });
  },
};
</script>
