<template>
  <v-dialog
    v-model="show"
    persistent
    :overlay="true"
    max-width="510px"
    transition="dialog-transition"
  >
    <v-card style="overflow: hidden;">
      <v-card-title
        class="pb-2 pt-1 px-3 blue darken-2"
         style="color:white;"
      >
        Change Password
        <v-spacer/>
        <v-btn icon dark small flat @click="$emit('cancel')">
          <v-icon>
            clear
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-expand-transition>
        <v-card-subtitle
          class="pa-0 py-2"
          v-show="showError"
          transition="fade-transition"
        >
          <div class="d-block error white--text text-center">
            {{ errForm.message }}
          </div>
        </v-card-subtitle>
      </v-expand-transition>

      <v-card-text>
        <v-container>
          <v-row
            ><v-text-field
              style="margin-top:16px; margin-right:90px;"
              ref="oldPassword"
              v-model="oldPassword"
              :rules="[rules.required, rules.minPassword]"
              label="Current Password*"
              :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showOldPassword ? 'text' : 'password'"
              @click:append="showOldPassword = !showOldPassword"
              outlined
              dense
            ></v-text-field>
          </v-row>

          <v-row>
            <v-text-field
              style="margin-top:16px; margin-right:90px;"
              ref="newPassword"
              v-model="newPassword"
              :rules="[rules.required, rules.minPassword]"
              label="New Password*"
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNewPassword ? 'text' : 'password'"
              @click:append="showNewPassword = !showNewPassword"
              outlined
              dense
            ></v-text-field>
          </v-row>

          <v-row>
            <v-text-field
              style="margin-top:16px; margin-right:90px;"
              ref="confirmPassword"
              v-model="confirmPassword"
              :rules="[rules.required, rules.minPassword, rules.confirmPass]"
              label="Confirm New Password*"
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNewPassword ? 'text' : 'password'"
              @click:append="showNewPassword = !showNewPassword"
              outlined
              dense
            ></v-text-field>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text class="text-capitalize" @click="$emit('cancel')"
          >Cancel</v-btn
        >
        <v-btn text class="text-capitalize" color="primary" @click="updatePass"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    props: ["show"],
    data() {
      return {
        oldPassword: "",
        showOldPassword: false,
        newPassword: "",
        showNewPassword: false,
        confirmPassword: "",
        errForm: {
          status: false,
          message: "",
        },
        rules: {
          required: (value) => !!value || "*Required",
          minPassword: (v) => v.length >= 6 || "Min 6 characters",
          confirmPass: (value) =>
            (value && value == this.newPassword) ||
            "Confimr password not match!",
        },
      };
    },
    computed: {
      ...mapGetters({
        userId: "userId",
        token: "token",
        role: "role",
      }),
      showError() {
        return this.errForm.status;
      },
    },
    watch: {
      show(val) {
        if (val) {
          setTimeout(() => {
            this.resetValidate();
          }, 50);
        } else {
          this.newPassword = "";
          this.oldPassword = "";
        }
      },
    },
    methods: {
      updatePass() {
        if (!this.isValidate()) return;
        var self = this;
        self.items = [];
        var path =
          this.$root.apiUser + "/users/updatePassword/" + self.userId;
        let dataSend = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        };
        self.axios
          .put(path, dataSend, { headers: { "x-auth": self.token } })
          .then((res) => {
            if(res.data.status=='OK'){
              self.$emit("saveDone", res.data.message);
            } else {
              self.errForm.message = res.data.message
              self.errForm.status = true
            }
            
          });
      },
      // editCategory() {
      //   let self = this;
      //   this.axios
      //     .put(
      //       this.$root.apiClient + "category/" + this.category._id + "/update",
      //       this.dataObj
      //     )
      //     .then((response) => {
      //       if (response.data.status == "OK") {
      //         self.$emit("saveDone");
      //       }
      //     })
      //     .catch(console.log);
      // },
      resetValidate() {
        if (
          this.$refs.oldPassword != undefined &&
          !this.$refs.oldPassword.validate()
        ) {
          this.$refs.oldPassword.reset();
        }
        if (
          this.$refs.newPassword != undefined &&
          !this.$refs.newPassword.validate()
        ) {
          this.$refs.newPassword.reset();
        }
        if (
          this.$refs.confirmPassword != undefined &&
          !this.$refs.confirmPassword.validate()
        ) {
          this.$refs.confirmPassword.reset();
        }
      },
      isValidate() {
        let result = true;
        this.$refs.oldPassword.validate(true);
        this.$refs.newPassword.validate(true);
        this.$refs.confirmPassword.validate(true);

        if (
          !this.$refs.oldPassword.validate() ||
          !this.$refs.newPassword.validate() ||
          !this.$refs.confirmPassword.validate()
        ) {
          this.errForm.status = true;
          setTimeout(() => {
            this.errForm.status = false;
          }, 3000);
          this.errForm.message = "Input info not valid, please check again!";
          result = false;
        }
        return result;
      },
    },
  };
</script>
