var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding":"2px","width":"100%","max-width":"700px"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0 mt-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-0",staticStyle:{"border":"1px solid #CCCCCC"},attrs:{"flat":""}},[_c('v-card-title',{staticClass:"px-4 py-1 blue darken-2",staticStyle:{"color":"white"}},[_vm._v(" Hồ sơ cá nhân ")]),_c('v-card-text',{staticClass:"pa-3"},[_c('div',{staticStyle:{"display":"block","padding":"6px","font-size":"16px"}},[_c('div',{staticStyle:{"display":"inline-block","font-weight":"350"},style:({
                width: _vm.$vuetify.breakpoint.mdAndUp ? '240px' : '100%',
              })},[_vm._v(" Email: ")]),_c('div',{staticStyle:{"display":"inline-block","font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.user.email)+" ")])]),_c('div',{staticStyle:{"display":"block","padding":"6px","font-size":"16px"}},[_c('div',{staticStyle:{"display":"inline-block","font-weight":"350"},style:({
                width: _vm.$vuetify.breakpoint.mdAndUp ? '240px' : '100%',
              })},[_vm._v(" Họ và tên: ")]),_c('div',{staticStyle:{"display":"inline-block","font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.user.fullname)+" ")])]),_c('div',{staticStyle:{"display":"block","padding":"6px","font-size":"16px"}},[_c('div',{staticStyle:{"display":"inline-block","font-weight":"350"},style:({
                width: _vm.$vuetify.breakpoint.mdAndUp ? '240px' : '100%',
              })},[_vm._v(" Điện thoại: ")]),_c('div',{staticStyle:{"display":"inline-block","font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.user.mobile)+" ")])]),_c('div',{staticStyle:{"display":"block","padding":"6px","font-size":"16px"}},[_c('div',{staticStyle:{"display":"inline-block","font-weight":"350"},style:({
                width: _vm.$vuetify.breakpoint.mdAndUp ? '240px' : '100%',
              })},[_vm._v(" Quyền cấp phép: ")]),_c('div',{staticStyle:{"display":"inline-block","font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.user.role)+" ")])]),_c('div',[_c('div',{staticStyle:{"display":"inline-block","font-weight":"350"},style:({
                width: _vm.$vuetify.breakpoint.mdAndUp ? '240px' : '',
              })},[_c('v-img',{staticStyle:{"border":"1px solid #DDDDDD","display":"inline-block"},attrs:{"width":"192","height":"192","contain":"","src":_vm.user.avatar ? _vm.user.avatar : '/imgs/user-placehold.jpg'}})],1),_c('div',{staticClass:" ml-2",staticStyle:{"display":"inline-block","vertical-align":"top"}},[_c('input',{ref:"imageInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"*/*"},on:{"change":_vm.handleFileUpload}}),_c('v-btn',{staticStyle:{"min-width":"20px","width":"46px"},attrs:{"color":"teal","outlined":""},on:{"click":_vm.startPickImage}},[_c('v-icon',[_vm._v(" add_photo_alternate ")])],1)],1)]),_c('div',{staticStyle:{"display":"block","padding":"6px","font-size":"16px"}},[_c('div',{staticStyle:{"display":"inline-block","font-weight":"350"},style:({
                width: _vm.$vuetify.breakpoint.mdAndUp ? '240px' : '100%',
              })},[_vm._v(" Bạn có muốn đổi mật khẩu ? ")]),_c('v-btn',{staticStyle:{"text-transform":"none","margin":"0px","height":"30px"},attrs:{"dark":"","color":"primary"},on:{"click":_vm.updatePass}},[_vm._v(" Đổi mật khẩu ")])],1)])],1)],1)],1),_c('v-snackbar',{staticStyle:{"top":"40px"},attrs:{"flat":"","dark":"","timeout":_vm.snack_timeout,"color":_vm.colorSnackBar,"top":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_message)+" ")]),_c('dialog-update-password',{attrs:{"show":_vm.dialogUpdatePass},on:{"cancel":function($event){_vm.dialogUpdatePass = false},"saveDone":_vm.updateDone}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }